<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>{{ contact ? contact.name : '' }}</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form @submit="handleLastAction" v-slot="{ errors }">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Last action</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.last_action }">
                                    <Field autocomplete="off" name="last_action" v-model="form.last_action" rules="required" label="last action">
                                        <multiselect
                                            v-model="form.last_action"
                                            :options="lastActions"
                                            value-prop="value"
                                            label="label"
                                            :searchable="true"
                                            placeholder="Select last action"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="last_action" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp" v-if="form && (form.last_action === 'Add An Option Custom')">
                            <div class="group_item">
                                <label class="input_label">Custom last action</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.custom_last_action }">
                                    <Field autocomplete="off" v-model="customLastAction" name="custom_last_action" type="text" rules="required" label="custom last action" />
                                </div>
                                <ErrorMessage name="custom_last_action" class="text-danger" />
                            </div>
                        </div>
                        <div class="action_wpr mt-5">
                            <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="loader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="loader"></i>{{ loader ? ' Saving' : 'Save' }}</button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex';

    import constants from '@/utils/Constant'

    export default {
        name: 'Contact Last Action',

        data () {
            return {
                form: {
                    contact_id : '',
                    last_action: ''
                },
                lastActions: [],
                loader: false,
                customLastAction: '',
            };
        },

        props: {
            modelValue: Boolean,
            contact: [Object, Array],
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;

                    vm.customLastAction   = '',
                    vm.lastActions        = [...constants.last_actions];

                    const index = vm.lastActions.findIndex(({value}) => value == 'Add An Option');
                    vm.lastActions.splice(index, 1);

                    vm.form = {
                        contact_id: vm.contact && vm.contact.id ? vm.contact.id : '',
                        last_action: vm.contact && vm.contact.last_action ? vm.contact.last_action : '',
                    };

                    if (vm.form.last_action && !vm.lastActions.find(({value}) => value == vm.form.last_action)) {
                        vm.lastActions.push({value: vm.form.last_action, label: vm.form.last_action});
                    }

                    vm.lastActions.push({value: 'Add An Option Custom', label: 'Add An Option'});
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        components: {
            Form,
            Field,
            ErrorMessage,
        },

        methods: {
            ...mapActions({
                updateLastAction: 'contactModule/updateLastAction',
            }),

            closeModal () {
                const vm = this;

                vm.resetForm();
                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form             = { contact_id : '', last_action: '' };
                vm.customLastAction = '';
                vm.loader           = false;
            },

            handleLastAction (form, { setFieldError }) {
                const vm     = this;
                const params = { ...vm.form };

                if (params && params.last_action == 'Add An Option Custom') {
                    params.last_action = vm.customLastAction;
                }

                params.setFieldError = setFieldError;
                vm.loader = true;

                vm.updateLastAction(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }

                    vm.loader = false;
                });
            },
        },
    }
</script>
